import React, { useState, useRef, useEffect } from 'react';
import Slide from '../components/slide';
import SlideBackground from '../components/slideBackground';
import styled from '@emotion/styled'

const Container = styled.div`
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  user-select: none;
`

function useInterval(callback, delay) {
  const savedCallback = useRef();
  const savedId = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  const tick = () => {
    savedCallback.current();
  }

  const reset = () => {
    clearInterval(savedId.current)
    savedId.current = setInterval(tick, delay)
  }

  const clear = () => {
    clearInterval(savedId.current)
  }

  useEffect(() => {
    savedId.current = setInterval(tick, delay);
    return () => clearInterval(savedId.current);
  }, [delay]);

  return [ reset, clear ]
}

function Index({ appData }) {
  const { homepage } = appData;
  const [index, setIndex ] = useState(0);

  const nextSlide = () => {
    setIndex( (index + 1) %  homepage.data.body.length);
    resetInterval();
  }

  const [ resetInterval, clear ] = useInterval(nextSlide, 2000);
  return (
    <Container
      onMouseDown={clear}
      onMouseUp={nextSlide}
      onTouchStart={clear}
      onTouchEnd={nextSlide}
    >
      { homepage.data.body.map( (slide, i) => (
        i <= (index + 1) && (
          slide["slice_type"] === "slide" ? (
            <Slide
              preload={i === (index + 1)}
              key={i}
              data={slide}
            />
          ) : (
            <SlideBackground
              preload={i === (index + 1)}
              key={i}
              data={slide}
            />
          )
        )
      ))}
    </Container>
  )
}

export default Index;
