import React from 'react'
import { RichText } from 'prismic-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { color } from 'styled-system'
import { Headline, Title } from '../components/text'
import { medium, large, mediumRetina, largeRetina} from './media-queries';

const left = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  @media only screen and (max-width: 768px) and (orientation: portrait) {
    width: 100%;
    height: 50%;
  }
`

const right = css`
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  @media only screen and (max-width: 768px) and (orientation: portrait) {
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
  }
`

const Block = styled.div(
  { position: 'absolute' },
  props => props.position === 'left / top' ? left : right
)

const BackgroundColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${color}
`

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${props => props.small});
   /* non Retina */
  ${medium}{
    background-image: url(${props => props.medium});
  }
  ${large}{
    background-image: url(${props => props.large});
  }
  /* Retina */
  ${mediumRetina}{
      background-image: url(${props => props.medium});
  }
  ${largeRetina}{
      background-image: url(${props => props.large});
  }
`

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled.svg`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* Ratio Media Queries */
  width: 75%;
  height: auto;
  @media (min-aspect-ratio: 2/1) {
    height: 75%;
    width: auto;
  }
  /* @media (max-width: 768px) {
    width: 75%;
    height: auto;
  } */
  @media (max-width: 768px) and (orientation: portrait){
    width: auto;
    height: 75%;
  }
  /* Image Media Queries  */
  background-image: url(${props => props.small});
  ${medium}{
    background-image: url(${props => props.medium});
  }
  ${large}{
    background-image: url(${props => props.large});
  }
  ${mediumRetina}{
      background-image: url(${props => props.medium});
  }
  ${largeRetina}{
      background-image: url(${props => props.large});
  }
`

const TextContainer = styled.div`
  max-width: 70%;
  max-height: 70%;
  user-select: none;
`

const isPortrait = ({height, width}) => {
  return height > width
}

const Slide = ({ data, preload }) => {

  // useEffect(() => {
  //   const update = () => {console.log('resize')};
  //   window.addEventListener('resize', update);
  //   return () => window.removeEventListener('resize', update);
  // }, [])

  return (
    <div
      css={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: preload ? 0 : 1
      }}
    >
      { data.items.map( ({
        position,
        image,
        text,
        caption,
        background_color: bgColor,
        text_color: textColor,
        background_image: bgImage,
      }) => (
        <Block key={position}  position={position} >
          { bgColor &&  <BackgroundColor bg={bgColor} /> }
          { bgImage.url && (
            <BackgroundImage
              small={bgImage.small.url}
              medium={bgImage.medium.url}
              large={bgImage.large.url}
            />
          )}
          { image.url && (
            <Container>
              { isPortrait(image.dimensions) ? (
                <Image
                  viewBox="0 0 100 100"
                  small={image['small-portrait'].url }
                  medium={image['medium-portrait'].url }
                  large={image['large-portrait'].url}
                  url={image.url}
                />
              ):(
                <Image
                  viewBox="0 0 100 100"
                  small={image['small-landscape'].url }
                  medium={image['medium-landscape'].url }
                  large={image['large-landscape'].url}
                  url={image.url}
                />
              )}

            </Container>
          )}
          { text && (
            <Container>
              <TextContainer>
                {text.map(({ text }) => (
                  <Headline textAlign="center" fontSize={[24,32]} color={textColor}>{text}</Headline>
                ))}
                { caption && (
                  <Title
                    color={textColor}
                    textAlign="center"
                    mt="8px"
                  >
                    {RichText.asText(caption)}
                  </Title>
                )}
              </TextContainer>
            </Container>
          )}
        </Block>
      ))}
    </div>
  );
}

export default Slide;
