import React from 'react'
import styled from '@emotion/styled'
import css from '@styled-system/css'

const Container = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 24px;
  z-index: 300;
  @media (max-width: 768px) and (orientation: portrait) {
    padding: 24px 0;
    height: 100%;
    width: 48px;
    flex-direction: column;
  }
`

const Row = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) and (orientation: portrait){
    flex-direction: column;
    width: auto;
    height: 50%;
  }
`

export const Logo = styled.h1(
  css({
    fontFamily: 'regular',
    fontWeight: 400,
    fontSize: 40,
    textTransform: 'uppercase',
    letterSpacing: 8,
    py: 0,
    px: '8px',
    color: 'white',
    '@media (max-width: 768px)': {
      fontSize: 24,
    },
    '@media (max-width: 768px) and (orientation: portrait)': {
      fontSize: 24,
      writingMode: 'vertical-rl',
      py: '8px',
      pl: 0,
    }
  })
)

const Header = ({ children }) => {
  return (
    <Container>
      <Row>
        {children}
        <Logo>Heath</Logo>
      </Row>
      <Row>
        <Logo>Studio</Logo>
      </Row>
    </Container>
  );
}

export default Header;
