const colors = {
  black: '#000000',
  white: '#FFFFFF',
  blue: '#0117FF',
  glass: "#0117FF",
  yellow: '#F7EA88',
  vanilla: "#F7EA88",
  green: '#47775D',
  oxidised: "#47775D",
  pink: '#F0B4AA',
  clay: "#F0B4AA",
}

const breakpoints = ['768px']

const colorStyles = {
  glass: {
    backgroundColor: colors.glass,
    color: colors.white,
  },
  vanilla: {
    backgroundColor: colors.vanilla,
    color: colors.black,
  },
  oxidised: {
    backgroundColor: colors.oxidised,
    color: colors.white,
  },
  clay: {
    backgroundColor: colors.clay,
    color: colors.black,
  }
}

const fonts = {
  regular: 'Beausite-Regular, sans-serif',
  light: 'Beausite-Light, sans-serif',
  thin: 'Beausite-Thin, sans-serif'
}

const textStyles = {
  caption: {
    fontFamily: fonts.light,
    fontSize: 16,
    fontWeight: 300,
    textTransform: 'uppercase'
  },
  text: {
    fontFamily: fonts.light,
    fontSize: 18,
    fontWeight: 300,
  },
  large: {
    fontFamily: fonts.thin,
    fontSize: 32,
    fontWeight: 300,
  },
  logo: {
    fontFamily: fonts.light,
    fontSize: 40,
    fontWeight: 300,
    textTransform: 'uppercase',
    letterSpacing: 8,
    '@media (max-width: 768px)': {
      fontSize: 32,
    }
  }
}

export default {
  breakpoints,
  fonts,
  colors,
  colorStyles,
  textStyles
}