import React from 'react'
import styled from '@emotion/styled'

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: ${props => props.preload ? 0 : 1};
  background-image: url(${props => props.smallDesktop});

  /* Mobil */
  @media (orientation: portrait) {
    background-image: url(${props => props.smallMobil});
  }
  @media (min-width: 553px) and (orientation: portrait){
    background-image: url(${props => props.mediumMobil});
  }
  /* Desktop */
  @media (min-width: 1281px){
    background-image: url(${props => props.mediumDesktop});
  }
  @media (min-width: 1921px){
    background-image: url(${props => props.largeDesktop});
  }
  /* Desktop Retina */
  @media
    (-webkit-min-device-pixel-ratio: 2)      and (min-width: 961),
    (   min--moz-device-pixel-ratio: 2)      and (min-width: 961),
    (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 961),
    (        min-device-pixel-ratio: 2)      and (min-width: 961),
    (                min-resolution: 192dpi) and (min-width: 961),
    (                min-resolution: 2dppx)  and (min-width: 961) {
    background-image: url(${props => props.largeDesktop});
  }
`

const SlideBackground = ({ data: { primary: {desktop, mobil} }, preload }) => {
  return (
    <Image
      preload={preload}
      smallMobil={mobil.small.url}
      mediumMobil={mobil.medium.url}
      smallDesktop={desktop.small.url}
      mediumDesktop={desktop.medium.url}
      largeDesktop={desktop.large.url}
    />
  );
}

export default SlideBackground;
