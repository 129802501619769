import React from "react"
import { Text } from '../components/text'


const AboutPage = ({ appData }) => {
  const { text } = appData.about.data
  return (
    <>
    <div>
      {text.map( ({text}) => (
        <Text mb="8px">{text}</Text>
      ))}
    </div>
    </>
  )
}

export default AboutPage;