import React from 'react';
import styled from '@emotion/styled'
import { layout, space } from 'styled-system'

const Svg = styled.svg(layout, space);
const Rect = styled.rect(layout);


const MenuButton = ({ open, toggleMenu }) => {
  if(open){
    return (
      <button aria-label="menu button" onClick={toggleMenu}>
        <Svg xmlns="http://www.w3.org/2000/svg" width={[24,32]} viewBox="0 0 32 32">
          <title>menu_close</title>
          <Rect fill="#fff" x="-1.9041" y="15.0777" width="35" height={["3px", "2px"]} transform="translate(-6.6753 18.469) rotate(-52)"/>
          <Rect fill="#fff" x="14.5959" y="-1.4223" width={["3px", "2px"]} height="35" transform="translate(-6.5922 13.0101) rotate(-38)"/>
        </Svg>
      </button>
    )
  }
  return (
    <button aria-label="menu button" onClick={toggleMenu}>
      <Svg xmlns="http://www.w3.org/2000/svg" width={[24,32]} viewBox="0 0 32 32">
        <title>menu</title>
        <Rect fill="#fff" x="4" y="27" width="24" height={["3px", "2px"]}/>
        <Rect fill="#fff" x="4" y="3" width="24" height={["3px", "2px"]}/>
        <Rect fill="#fff" x="4" y="15" width="24" height={["3px", "2px"]}/>
      </Svg>
    </button>
  )
}

export default MenuButton;