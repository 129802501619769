import React from "react"
import { RichText } from 'prismic-dom'
import { Text, Title } from '../components/text'
import styled from '@emotion/styled'


const Block = styled.div`
  margin-bottom: 64px;
`

const AboutPage = ({ appData }) => {
  const { adresse, mail, phone, instagram } = appData.contact.data;
  return (
    <div>
      <Block>
        <Title mb="8px">Adresse</Title>
        {adresse.map(({ text }) => (
          <Text>{text}</Text>
        ))}
      </Block>
      <Block>
        <Title mb="8px">Email</Title>
        <Text href={`mailto:${RichText.asText(mail)}`} as="a">{RichText.asText(mail)}</Text>
      </Block>
      <Block>
        <Title mb="8px">Phone</Title>
        <Text>{RichText.asText(phone)}</Text>
      </Block>
      <Block>
        <Title as="a" target="_blank" display="inline-block" rel="noopener noreferrer" href={instagram} >Instagram</Title>
      </Block>
    </div>
  )
}

export default AboutPage;