import styled from '@emotion/styled'
import css from '@styled-system/css'
import { color, space, typography, layout, compose } from 'styled-system'

const styleProps = compose(space, color, typography, layout)

export const Title = styled.h3(
  css({
    fontFamily: 'light',
    fontWeight: 300,
    fontSize: 16,
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    marginTop: '-3px'
  }),
  styleProps
)

export const Text = styled.p(
  css({
    fontFamily: 'light',
    fontWeight: 300,
    fontSize: 18,
  }),
  styleProps
)

export const Headline = styled.h2(
  css({
    fontFamily: 'thin',
    fontWeight: 100,
    fontSize: 32,
    lineHeight: 1.1,
  }),
  styleProps
)

export const Logo = styled.h1(
  css({
    fontFamily: 'light',
    fontWeight: 300,
    fontSize: 40,
    textTransform: 'uppercase',
    letterSpacing: 8,
    '@media (max-width: 768px)': {
      fontSize: 32,
    }
  }),
  styleProps
)

