import React from 'react';
import styled from '@emotion/styled'
import { Link } from "react-router-dom";
import { color } from 'styled-system'
import themeGet from '@styled-system/theme-get'

const Menu = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  background-color: blue;
  transform: translateX( ${({open}) => open ? '0' : '-100%'} );
  z-index: 200;
  ${color}
  @media (max-width: 768px) and (orientation: portrait) {
    width: 100%;
    height: 50%;
  }
`

const StyledLink = styled(Link)`
  padding: 8px;
  color: inherit;
  color: white;
  text-decoration: none;
  color: ${({active}) => active ? 'white' : 'black'};
  ${themeGet('textStyles.large')}
`


const MenuItem = ({ children, href, pathname, ...props }) => (
  <StyledLink to={href} active={href === pathname} {...props} >
    {children}
  </StyledLink>
)

export default Menu
export { MenuItem }