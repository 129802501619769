import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import { Global } from '@emotion/core'
import { withRouter } from 'react-router-dom';
import styled from '@emotion/styled'
import Header from './header'
import MenuButton from './menuButton'
import Menu, { MenuItem } from "./menu"
import theme from '../theme'

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  height: ${({viewport}) => viewport && viewport };
`

const FullScreen = styled.main`
  position: absolute;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 768px) and (orientation: portrait) {
    min-height: 100%;
    height: auto;
  }
`

const SplitScreen = styled.main`
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  padding: 128px 64px 64px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  @media (max-width: 768px){
    padding: 96px 16px 16px;
  }
  @media (max-width: 768px) and (orientation: portrait) {
    padding: 16px 48px;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
  }
`

const Layout = ({children, appData}) => {
  const history = useHistory();
  const { pathname } = useLocation()
  const [ menuOpen, setMenuOpen ] = useState(pathname !== '/');
  const [ viewport, setViewport ] = useState(null);
  const isHomepage = pathname === '/';
  let currentData = {};

  switch(pathname) {
    case '/':
      currentData = appData.homepage.data
      break;
    case '/about':
      currentData= appData.about.data
      break;
    case '/contact':
      currentData = appData.contact.data
      break;
    case '/news':
      currentData = appData.news.data
      break;
    default:
      currentData = appData.homepage.data
  }

  let { menu, background } = currentData;

  const toggleMenu = () => {
    if(isHomepage){
      setMenuOpen(state => !state)
    }
    else{
      history.push('/')
    }
  }

  useEffect(() => {
    if(pathname === '/'){
      setMenuOpen(false)
    }
    else{
      setMenuOpen(true)
    }
  }, [pathname])


  useEffect(() => {
    const update = () => setViewport(window.innerHeight + 'px');
    update();
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [])


  return (
    <Container viewport={viewport}>
      <GlobalStyle bgColor={background} />
      <Header>
        <MenuButton
          open={menuOpen}
          toggleMenu={toggleMenu}
          pathname={pathname}
        />
      </Header>
      <Menu open={menuOpen} bg={menu}>
        <MenuItem href="/about" pathname={pathname}>
          About
        </MenuItem>
        <MenuItem href="/contact" pathname={pathname}>
          Contact
        </MenuItem>
        <MenuItem href="/news" pathname={pathname}>
          News
        </MenuItem>
      </Menu>
        { isHomepage ? (
          <FullScreen>
            {children}
          </FullScreen>
        ):(
          <SplitScreen>
            {children}
          </SplitScreen>
        )}
    </Container>
  )
}


const GlobalStyle = ({ bgColor }) => {
  return (
    <Global
      styles={{
        'body': {
          ...theme.colorStyles[bgColor],
        }
      }}
    />
  )
}

export default withRouter(Layout);