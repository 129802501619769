
import React, { Component, useState } from "react";
import { RichText } from 'prismic-dom'
import { Text, Title } from '../components/text'
import { Portal } from 'react-portal';
import styled from '@emotion/styled';
import MenuButton from '../components/menuButton'

const StyledNewsBlock = styled.div`
  margin-bottom: 56px;
`

const NewsTitle = styled(Title)`
  display: inline;
  margin-right: 6px;
`

const Image = styled.img`
  cursor: pointer;
  width: 100%;
  user-select: none;
`

const Overlay = styled.div( props => ({
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 800,
  padding: 60,
  ...props.theme.colorStyles[props.bg],
  '@media (max-aspect-ratio: 1/1)':{
    padding: '80px 0'
  }
}))

const OverlagImage = styled.div`
  cursor: pointer;
  width: auto;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${props => props.small});
  @media (max-aspect-ratio: 1/1){
    left: 8px;
  }
  @media (min-width: 1200px){
    background-image: url(${props => props.medium});
  }
`

const NavLeft = styled.div`
  position: absolute;
  display: flex;
  width: 40px;
  height: 100%;
  left: 24px;
  top: 0;
  align-items: center;
  justify-content: center;
  @media (max-aspect-ratio: 1/1){
    left: 8px;
  }
`

const NavRight = styled.div`
  position: absolute;
  display: flex;
  width: 40px;
  height: 100%;
  right: 24px;
  top: 0;
  align-items: center;
  justify-content: center;
  @media (max-aspect-ratio: 1/1){
    right: 8px;
  }
`

const CloseButton = styled.div`
  position: absolute;
  display: flex;
  width: 40px;
  height: 80px;
  right: 24px;
  align-items: center;
  top: 0;
  align-items: center;
  justify-content: center;
  @media (max-aspect-ratio: 1/1){
    right: 8px;
  }
`

const NewsOverlay = ({bg, images, title, onClose }) => {
  const [index, setIndex] = useState(0);

  const prev = () => {
    if(index === 0){
      setIndex(images.length - 1)
    }
    else{
      setIndex(index - 1 % images.length)
    }
  }

  const next = () => setIndex( (index + 1) % images.length)
  let image = images[index].image

  return (
    <Portal node={document && document.getElementById('modal')}>
      <Overlay bg={bg} >
          <NavLeft>
            {images.length > 1 && (
              <ArrowLeft onClick={prev} />
            )}
          </NavLeft>
          <OverlagImage
            small={image.small.url}
            medium={image.medium.url}
          />
          <NavRight>
            {images.length > 1 && (
              <ArrowRight onClick={next} />
            )}
          </NavRight>
          <CloseButton onClick={onClose}>
            <MenuButton open={true} toggleMenu={onClose} />
          </CloseButton>
          {/* <Text mt="8px">
            < NewsTitle as="span">{
                RichText.asText(title)}
            </NewsTitle>
          </Text> */}
      </Overlay>
    </Portal>
  )
}


const NewsBlock = ({images, text, newstitle, bg}) => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const image = images[0].image
  return (
    <>
      { overlayOpen && (
        <NewsOverlay
          bg={bg}
          onClose={()=>setOverlayOpen(false)}
          images={images}
          title={newstitle}
          text={text}
        />
      )}
      <StyledNewsBlock>
        { image.url && (
          <Image
            onClick={() => setOverlayOpen(true)}
            src={image.small.url}
            srcSet={`${image.small.url} 1240w, ${image.medium.url} 1800w, ${image.large.url} 2400w`}
          />
        )}
        <Text mt="8px">
          <NewsTitle as="span">
            {RichText.asText(newstitle)}
          </NewsTitle>
          {RichText.asText(text)}
        </Text>
      </StyledNewsBlock>
    </>
  )
}

class AboutPage extends Component {
  render() {
    let { news } = this.props.appData;
    return (
      <div>
        {news.data && news.data.body.map( ({primary, items}, index) => (
            <NewsBlock
              bg={news.data.background}
              key={index}
              images={items}
              text={primary.text}
              newstitle={primary.newstitle}
            />
        ))}
      </div>
    );
  }
}

const ArrowRight = ({onClick}) => {
  return(
    <button onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="50" viewBox="0 0 30 50">
        <g id="right" transform="translate(-1226 -376)">
          <path id="Path_1" data-name="Path 1" d="M2606.834,55l14.909-15-14.909-15" transform="translate(-1373 360.5)" fill="none" stroke="#fff" stroke-width="3"/>
          <rect id="Rectangle_8" data-name="Rectangle 8" width="30" height="50" transform="translate(1226 376)" fill="#fff" opacity="0"/>
        </g>
      </svg>
    </button>
  )
}

const ArrowLeft = ({onClick}) => {
  return(
    <button onClick={onClick}>
      <svg id="Left" xmlns="http://www.w3.org/2000/svg" width="30" height="50" viewBox="0 0 30 50">
        <path id="Path_1" data-name="Path 1" d="M2621.743,55l-14.909-15,14.909-15" transform="translate(-2599.577 -15.5)" fill="none" stroke="#fff" stroke-width="3"/>
        <rect id="Rectangle_8" data-name="Rectangle 8" width="30" height="50" fill="#fff" opacity="0"/>
      </svg>
    </button>
  )
}

export default AboutPage;