import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {Helmet} from "react-helmet";
import { Client } from './prismic'
// import { RichText } from 'prismic-dom'
import { ThemeProvider } from 'emotion-theming'
import Layout from "./components/layout"
import theme from './theme'
import About from './pages/about'
import Contact from './pages/contact'
import News from './pages/news'
import Home from './pages/index'

async function fetchData() {
  const news = await Client().getSingle('news');
  const about = await Client().getSingle('about');
  const contact = await Client().getSingle('contact');
  const seo = await Client().getSingle('seo');
  const homepage = await Client().getSingle('homgepage');
  return { news, about, contact, seo, homepage }
}

const App = () => {
  const [data, setData] = useState();

  useEffect(()=>{
    async function fetch() {
      const data = await fetchData()
      setData(data)
    }
    fetch();
  },[])

  if(!data) return null

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Layout appData={data} >
          {/* <Helmet>
            <title>{seo.data.title || ''}</title>
            <meta property="og:title" content={seo.data.title || ''} />
            <meta
              name = "description"
              content = {seo.data.description ? RichText.asText(seo.data.description) : ''}
            />
            <meta
              property="og:description"
              content = {seo.data.description ? RichText.asText(seo.data.description) : ''}
            />
            <meta property="og:image" content={seo.data.image.url} />
            <meta name="twitter:card" content={seo.data.image.url} />
          </Helmet> */}
          <Switch>
            <Route path="/about">
              <About appData={data} />
            </Route>
            <Route path="/contact">
              <Contact appData={data} />
            </Route>
            <Route path="/news">
              <News appData={data} />
            </Route>
            <Route path="/">
              <Home appData={data} />
            </Route>
          </Switch>
        </Layout>
      </ThemeProvider>
    </Router>
  );
}

export default App;