export const medium = `@media (min-width: 1680px)`;
export const large = `@media (min-width: 2400px)`;

export const mediumRetina = `@media
only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 840px),
only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 840px),
only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 840px),
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 840px),
only screen and (                min-resolution: 192dpi) and (min-width: 840px),
only screen and (                min-resolution: 2dppx)  and (min-width: 840px)`

export const largeRetina = `@media
only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1200px),
only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1200px),
only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1200px),
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1200px),
only screen and (                min-resolution: 192dpi) and (min-width: 1200px),
only screen and (                min-resolution: 2dppx)  and (min-width: 1200px)`